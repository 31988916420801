<template>
  <div class="flex flex-col items-center w-full h-full">
    <div class="flex flex-col w-full h-full overflow-auto"></div>
  </div>
</template>
<script>
export default {
  name: "Show",
  components: {},
  methods: {
    //
  }
};
</script>
